import Vue from 'vue';
import { Button, Calendar, Row, Col, Scrollbar, Menu, Submenu, MenuItem, Breadcrumb, BreadcrumbItem, Container, Main, Alert, Tree, Form, FormItem, Input, Select, Option, DatePicker, Pagination, Upload, CheckboxGroup, Checkbox, Descriptions, DescriptionsItem, Tag } from 'element-ui';

Vue.use(Button);
Vue.use(Calendar);
Vue.use(Row);
Vue.use(Col);
Vue.use(Scrollbar);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Container);
Vue.use(Main);
Vue.use(Alert);
Vue.use(Tree);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(DatePicker);
Vue.use(Pagination);
Vue.use(Upload);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Tag);
