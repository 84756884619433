export default {
  Message: {
    Confirm: '確認',
    Cancel: '關閉',
    Title: '信息',
    msg401: '權限不足！',
    msg400: '系統異常！',
    msg404: '請求接口不存在！',
    msg500: '服務器錯誤！',
    msg502: '网络错误！',
    msg503: '服務不可用，服務器暫時過載或維護。'
  },
  DeliveryAddress: {
    Address: '地址'
  },
  Order: {
    OrderNumber: '訂單編號',
    OrderDateTime: '訂單日期&時間 ',
    TotalPrice: '總價',
    OrderStatus: '訂單狀態',
    Paid: '已付款',
    Unpaid: '待付款',
    PayNow: '立即支付'
  },
  RegNPay: {
    BackText: '返回',
    UploadButtonText: '上傳文件(jpg, png, pdf)',
    UploadingText: '正在上傳',
    ConfirmText: '確定',
    UploadSuccessfulText: '上傳成功',
    UploadFailText: '上傳失敗',
    NoFileText: '沒有選中文件',
    UploadLengthText: '最多只能上載{0}檔案',
    UploadSizeText: '最多只能上傳{0}M',
    PleaseSelect: '請選擇',
    SubRecord: '提交紀錄',
    ReferenceNum: '參考編號',
    SubmissionDate: '提交日期',
    Print: '列印',
    PlsPayWay: '請選擇付款方式',
    PreviewTitleText: '請再次確認頁面',
    FormatErrorText: '請輸入正確電郵地址',
    RequiredText: '請檢查你的答案'
  },
  Display: {
    SuccessCase: '成功個案',
    GeneralQuery: '壹般查詢',
    OfficeHours: '辦公時間',
    Hour0: '星期壹至五：',
    Hour1: '早上9時至下午12時,',
    Hour2: '下午1時30分至晚上6時',
    Hour3: '星期六、日及公眾期假休息',
    AddrInfo: '長沙灣荔枝角道 777號田氏企業中心 3樓 306室 (長沙灣港鐵站 B出口)'
  },
  Action: {
    ViewMore: '查看更多',
    SignUp: '立即報名',
    ReturnHomePage: '返回首頁',
    OrderDetails: '訂單詳情',
    ViewDetail: '瞭解更多'
  },
  Cms: {
    PlsChoose: '請點擊選擇您想要查看的內容：',
    LearnMore: '了解更多'
  },
  payment: {
    PaymentSuccessTips: '支付結果： 成功',
    PaymentFailTips: '支付結果： 失敗'
  },
  Calendar: {
    KnowMore: '了解更多',
    Time: '時間',
    Venue: '地點',
    NoEvent: '今日暫無活動'
  },
  photo: {
    photo: '相片',
    BackText: '返回上一級'
  },
  pager: {
    Last: '上一頁',
    Next: '下一頁',
    per: '第',
    page: '頁',
    Total: '共'
  },
  Home: {
    Home: '首頁',
    Search: '搜索結果',
    ReadMore: '更多'
  },
  BoAForm: {
    FirstName: '名字',
    LastName: '姓氏',
    Industry: '行業',
    Title: '標題',
    CompanyName: '公司名稱',
    Email: '電郵',
    Phone: '電話',
    RoundFunding: '融資類型',
    Country: '國家',
    Currency: '貨幣',
    Location: '項目地點',
    AnnualRevenue: '年收入',
    NetAsset: '資產淨值(NAV)',
    FundRaised: '籌款',
    Summary: '公司簡介',
    SubmitDate: '提交日期',
    ProjectName: '項目名稱',
    ProjectNatural: '項目性質',
    ProjectDesc: '項目描述',
    NatureEntity: '實體性質',
    Sector: '部門',
    InvestmentSize: '投資規模',
    Search: '查詢',
    Submit: '提交',
    SelectFile: '選擇文件+',
    UploadInvestmentDeck: '上傳投資組合資料',
    InvestmentDeck: '投資組合資料',
    OtherInformation: '其他信息',
    AgreeTerms: '我同意使用條款',
    MaxSize: '文件大小不能超過',
    Required: '必填',
    Privacy: '私隱政策',
    Terms: '條件與條款',
    Disclaimer: '免責聲明',
    Last: '上一頁',
    Next: '下一頁',
    SelectDate: '選擇日期',
    SubmitSuccess: '提交成功',
    SubmitFail: '提交失敗',
    CorrectEmail: '請輸入正確的電郵',
    CorrectPhone: '請輸入正確的電話',
    Code: '代碼',
    Identity: '身份',
    ProjectSection: '項目部分',
    ProjectOwner: '項目擁有者',
    exceed: '超出數量',
    exceedText: '請先刪除原有文件，再重新選擇文件，因為超出1個文件限制。'
  }
};
